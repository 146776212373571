<template>
    <div :class="['scroll-bar', type]">
        <div class="scroll-bar-content"  @click="scrollTo()">
            <p>{{ text }}</p>
            <div :class="['btn-arrow' , getColor() ]"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ScrollBar",
    props : ['text', 'type', 'color', 'link'],
    methods : {
        getColor() {
            if(this.color == "main") {
                return "arrow-main";
            }else if(this.color == "second") {
                return "arrow-second";
            }else if(this.color == "third") {
                return "arrow-third"; 
            }else {
                return "arrow-white";
            }
        },
        scrollTo() {
            const anchor = this.$route.path + this.link;
            this.$router.push(anchor);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
 .btn-arrow {
    width:40px;
    height:22px;
    scale:1;
    transition:0.5s;
  } 

  .arrow-main {
    background-image: url('./../assets/scroll-main.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

 .arrow-white {
    background-image: url('./../assets/scroll-white.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .arrow-third {
    background-image: url('./../assets/scroll-third.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

.arrow-second {
    background-image: url('./../assets/scroll-second.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .scroll-bar {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $secondColor;
    height:70px;
    z-index: 9;
  }

  .scroll-bar.light {
    background:none;
    bottom:10px;
  }

  .scroll-bar p{
    color:#fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom:10px;
  }

  .scroll-bar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      cursor:pointer;
  }

  .scroll-bar-content:hover {
     transform:scale(0.9);
  }
</style>