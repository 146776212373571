<template>
    <div class="banner">
      <video class="banner-video" autoplay muted loop preload>
         <source src="./../assets/video_home.mp4" type="video/mp4">
      </video>
      <div class="banner-resp">
        <div class="banner-btns">
          <router-link class="btn" :to="{ name : 'Advert'}">Régie publicitaire</router-link>
          <router-link class="btn" :to="{ name : 'Agency'}">Agence créative</router-link>
        </div>
        <div class="banner-gif">
          <img src="./../assets/home-gif.gif" alt="banner gif" />
        </div>
      </div>
      <scroll-bar
        text="régie publicitaire"
        color="main"
        link="#gms"
      ></scroll-bar>

    </div>

    <!-- GMS SECTION NEW-->
    <section id="gms" class="section-default section-gms">
      <div class="col-left">
        <div class="col-left-container">
          <h2>Notre couverture numérique en gms et en galeries</h2>
        </div>
      </div>
      <div class="col-main">
        <div class="bg-main"></div>
        <div class="col-container">
          <div class="gms-title">
            <img src="./../assets/gms-icon.png" alt="gms" />
            <h3>Notre réseau</h3>            
          </div>
          <div class="gms-row">
            <div class="col-50">
              <p>Prenez la parole au niveau national ou au niveau régional, local ou <strong>dans un centre commercial en particulier</strong> selon <strong>votre stratégie</strong> et <strong>votre budget</strong>. </p>
            </div>
            <div class="col-50">
              <p> Communiquez sur notre parc d'écrans digitaux implanté dans les <strong>centres commerciaux</strong> et les <strong>galeries marchandes</strong> des plus grosses agglomérations françaises (+220 galeries et +250 points de ventes)</p>
            </div>
          </div>
          <div class="gms-btn-container">
            <router-link  :to=" { name : 'Advert'}" class="btn">Découvrir</router-link>
          </div>
        </div>
        <scroll-bar
          text="agence créative"
          color="third"
          type="light"
          link="#agency"
        ></scroll-bar>
      </div>

    </section>

    <!--GMS SECTION -->
    <!-- <section id="gms" class="section-default section-gms">
      <div class="col-left">
        <div class="col-left-container">
          <h2>Les dispositifs GEOSKY en GMS</h2>
        </div>
      </div>
      <div class="col-main">
        <div class="bg-main"></div>
          <div class="col-container">
            <div class="col-33">
                <div class="col-item">
                    <p><strong>Geosky</strong> BORNE</p>
                    <router-link class="btn" :to=" { name : 'Advert'}">découvrir</router-link>
                </div>
            </div>
            <div class="col-33">
                <div class="col-item">
                    <p><strong>Geosky</strong> ECRAN<br />GRAND FORMAT</p>
                    <router-link class="btn" :to=" { name : 'Advert'}">découvrir</router-link>
                </div>
            </div>
            <div class="col-33">
                <div class="col-item">
                    <p><strong>Geosky</strong> CHARIOT</p>
                    <router-link class="btn" :to=" { name : 'Advert'}">découvrir</router-link>
                </div>
            </div>
        </div>
        <scroll-bar
          text="geosky galeries"
          color="second"
          type="light"
          link="#geosky"
        ></scroll-bar>
      </div>

    </section> -->

    <!-- GEOSKY SECTION -->
    <!-- <section id="geosky" class="section-default section-geosky">
      <div class="col-left">
        <div class="col-left-container">
          <h2>Communiquez dans<br /> les centres<br />commerciaux</h2>
        </div>
      </div>
      <div class="col-main">
        <div class="bg-main"></div>
        <div class="col-container">
            <div class="col-25">
                <div class="col-item">
                    <p><strong>Geosky</strong> galeries</p>
                    <router-link class="btn" :to=" { name : 'Advert'}">découvrir</router-link>
                </div>
            </div>
        </div>
        <scroll-bar
          text="agence créative"
          color="third"
          type="light"
          link="#agency"
        ></scroll-bar>
      </div>

    </section> -->

    <!-- AGENCY SECTION -->
    <section id="agency" class="section-default section-agency">
      <div class="col-left">
        <div class="col-left-container">
          <h2>Agence créative</h2>
          <p>Marjonat Distribution est devenu une agence dynamique de communication, de conception graphique et de stratégie publicitaire.</p>
        </div>
      </div>
      <div class="col-main">
        <div class="bg-main"></div>
        <div class="col-container col-third">
          <div class="col-33">
              <div class="col-item">
                  <p><strong>Animation</strong> & <strong>Packaging 3D</strong></p>
                  <router-link class="btn" :to=" { name : 'Agency', hash : '#anim'}">découvrir</router-link>
              </div>
          </div>
          <div class="col-33">
              <div class="col-item">
                  <p><strong>Packshot produits</strong> & <strong>visuels</strong></p>
                  <router-link class="btn" :to=" { name : 'Agency', hash : '#products'}">découvrir</router-link>
              </div>
          </div>
          <div class="col-33">
              <div class="col-item">
                  <p><strong>motion design</strong></p>
                  <router-link class="btn" :to=" { name : 'Agency', hash : '#design'}">découvrir</router-link>
              </div>
          </div>
          <!-- <div class="col-25">
              <div class="col-item">
                  <p><strong>Application pour les forces de vente</strong></p>
                  <router-link class="btn" :to=" { name : 'Agency', hash : '#appli'}">découvrir</router-link>
              </div>
          </div> -->
        </div>
        <scroll-bar
          text="Ils nous font confiance"
          color="white"
          type="light"
          link="#projects"
        ></scroll-bar>
      </div>
 
    </section>

    <!-- PROJECTS SECTION -->
    <section id="projects" class="section-default section-projects">
      <div class="col-left">
        <div class="col-left-container">
          <h2>Quelques marqueS<br />qui nous font<br />confiance</h2>
        </div>
      </div>
      <div class="col-main">
        <div class="brand-container">
          <brands></brands>
        </div>
      </div>
    </section>
</template>

<script>
import ScrollBar from "./../components/ScrollBar.vue";
import Brands from "./../components/Brands";

export default {
  name: 'Home',
  components : { 
    ScrollBar,
    Brands
  }

}
</script>


<style lang="scss" scoped>
@import "./../scss/variables.scss";

  .banner {
    height:100vh;
    width:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
  }

  .banner-video {
    position: absolute;
    right: 0;
    bottom: 0;
    top:0;
    min-width: 100%;
    min-height: 100vh;
  }

  /* GMS */

  .section-gms .col-left {
    background:$mainColor;
  }

  .section-gms .bg-main {
    background-image: url("./../assets/gms-bg.png");
  }

  .section-gms .col-container {
    flex-direction: column;
    align-items: center;
    top:0;
    bottom:0;
  }

  .gms-row {
    display: flex;
    width: 100%;
    margin:80px 0;
  }

  .gms-row p {
    color:#fff;
    font-size:18px;
    text-align: center;
    padding:0 5%;
  }

  .gms-row  .col-50 {
    width:50%;
  }

  .gms-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gms-title img {
    width: 80px;
    margin-bottom:20px;
  }

  .gms-title h3 {
    color:#fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size:25px;
    margin:0;
  }

  /* GEOSKY */

  .section-geosky .col-left {
    background:$secondColor;
  }

  .section-geosky .bg-main {
    background-image: url("./../assets/galery-bg.png");
  }

  /* AGENCY */

  .section-agency .col-left {
    background:$thirdColor;
  }

  .section-agency .col-left p, .section-agency .col-left h2 {
    color:$secondColor;
  }

  .section-agency .bg-main {
    background-image: url("./../assets/agency-bg.jpg");
  }

  /* PROJECTS */

  .section-projects .col-left {
    background:$secondColor;
  }

  .section-projects {
    min-height:400px;
  }

  .section-projects .col-main {
    margin:80px 0;
  }

  .brand-container {
    margin:0 40px;
  }

   .banner-resp  {
     display:none;
   }

  @media screen and (max-width:1100px) {
    .section-gms .col-main {
      background-image: url("./../assets/gms-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .section-geosky .col-main {
      background-image: url("./../assets/galery-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .section-agency .col-main {
      background-image: url("./../assets/agency-bg.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .section-projects .col-main {
      margin:0;
    }

    .banner-video {
      display: none;
    }

    .banner-resp {
      display: flex;
      flex-direction: column;
      align-items:center;
    }

    .banner {
      background-image: url("./../assets/banner-bg-resp.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      justify-content: flex-start;
      padding-top:180px;
      height:auto;
    }

    .banner-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom:40px;
    }

    .banner-btns .btn {
      width:200px;
      text-align: center;
      background:transparent;
      border:1px solid #fff;
      color:#fff;
      margin:20px auto;
    }

    .banner-gif {
      padding-bottom:80px;
      display: flex;
      justify-content: center;
      padding-top:40px;
    }

    .banner-gif img {
      max-width: 90%;
      padding-bottom:40px;
    }

    .gms-row {
      flex-direction: column;
      align-items: center;
      margin:40px 0;
    }
    
    .gms-row .col-50 {
      width:100%;
      margin:10px 0;
    }

  }

</style>