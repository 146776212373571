<template>
    <div class="brands-container">
        <div class="logo-item" v-for="(item, idx) in brands" :key="idx">
            <img :src="item.img" alt="logo" class="logo" />
        </div>
    </div>
</template>

<script>
export default {
    name : "Brand",
    props : ["type"],
    data() {
        return {
            brands : [
                {
                    img : require('@/assets/logos/ffr.png')
                },
                      {
                    img : require('@/assets/logos/barilla.png')
                },
                      {
                    img : require('@/assets/logos/BTB.png')
                },      {
                    img : require('@/assets/logos/Evian.png')
                }
                ,      {
                    img : require('@/assets/logos/fdj.png')
                }
                ,      {
                    img : require('@/assets/logos/fuzetea-logo.png')
                }
                ,      {
                    img : require('@/assets/logos/fuztea.png')
                }
                ,      {
                    img : require('@/assets/logos/Mars.png')
                }
                ,      {
                    img : require('@/assets/logos/vinci.png')
                }
                ,      {
                    img : require('@/assets/logos/schmidt.png')
                }
                ,      {
                    img : require('@/assets/logos/Orangina.png')
                }
                ,      {
                    img : require('@/assets/logos/Sodebo.png')
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .brands-container {
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .logo-item {
        width:180px;
        height:180px;
        background:#fff;
        margin:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
        transition: 0.5s;
    }

    .logo {
        width:90%;
        max-width: 90%;
        transition: 0.5s;
        filter:grayscale(100%)
    }

    .logo-item:hover .logo {
        filter:grayscale(0%)
    }

    @media screen and (max-width:768px) { 
        .logo-item {
            width:120px;
            height:120px;
        }
    }
</style>